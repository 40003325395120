import { Link, navigate } from "@reach/router"; 

const Publish = () => {
  const handleSubmit = (e) => {
    e.preventDefault();
    const title = e.target.title.value;
    const username = e.target.username.value;
    const content = e.target.content.value;
    if (title === "") {
      alert("title is empty")
      return
    }
    if (username === "") {
      alert("username is empty")
      return
    }
    if (content === "") {
      alert("content is empty")
      return
    }
    const body = {
      title: title,
      username: username,
      content: content
    };
    console.log(process.env.REACT_APP_API + "/posts")
    fetch(process.env.REACT_APP_API + "/posts", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(body)
    }).then((resp) => {
      resp.text().then((text) => {
        if (text === "success") {
          alert("post publish success!")
          navigate("/")
        } else {
          alert("post publish fail!")
        }
      });
    });
  };
  return (
    <div>
      <Link to="/">Return</Link>
      <form onSubmit={handleSubmit}>
        <label>Title</label>
        <br/>
        <input type="text" name="title" id="title" />
        <br/>
        <label>Username</label>
        <br/>
        <input type="text" name="username" id="username" />
        <br/>
        <label>Content</label>
        <br/>
        <textarea cols="40" rows="5" name="content" id="content" />
        <br/>
        <input type="submit" value="Publish" />
      </form>
    </div>
  );
}

export default Publish;
