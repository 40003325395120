import React, { useEffect, useState } from "react";
import { Link } from "@reach/router";

const Posts = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    console.log(process.env.REACT_APP_API)
    const getPosts = async () => {
      const resp = await fetch(
        process.env.REACT_APP_API + "/posts"
      );
      const postsResp = await resp.json();
      setPosts(postsResp);
    };

    getPosts();
  }, []);

  return (
    <div>
      <h1>Posts</h1>
      <Link to="/publish">Publish</Link>
      {posts.map((post) => (
        <div key={post.id}>
          <h2>
            {post.title}
          </h2>
          <h3>
            {post.username}
          </h3>
          <p>
            {post.content}
          </p>
          <hr/>
        </div>
      ))}
    </div>
  );
};

export default Posts;
